
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import Loader from "./components/common/Loader.js";
import "./assets/sass/index.scss"




const App = React.lazy(() => import("./App"));




const root = document.getElementById('root');
ReactDOM.render(
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>, 
    root
);

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
